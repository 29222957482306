import React from 'react';
import { FloatingLabel, Form, Offcanvas } from 'react-bootstrap';
import { useValues } from '../../contexts/AppContext';

const FormMoreOptions = () => {
    const { showFormBar, setShowFormBar } = useValues();
    const { originSide, setOriginSide, destinationSide, setDestinationSide, responseFormat, setResponseFormat, shiftedPolyline, setshiftedPolyline, avoidObstacles, setAvoidObstacles, language, setLanguage } = useValues();
    const handleClose = () => setShowFormBar(false);
    return (
        <Offcanvas scroll={true} backdrop={false} show={showFormBar} onHide={handleClose} placement='end'>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>More options</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Form>
                    <FloatingLabel controlId='selectOriginSide' label="Select origin side" className="px-1 mb-2">
                        <Form.Select value={originSide} onChange={e => setOriginSide(e.target.value)}>
                            <option value='left'>Left</option>
                            <option value='right'>Right</option>
                        </Form.Select>
                    </FloatingLabel>
                    <FloatingLabel controlId='selectDestinationSide' label="Select destination side" className="px-1 mb-2">
                        <Form.Select value={destinationSide} onChange={e => setDestinationSide(e.target.value)}>
                            <option value='left'>Left</option>
                            <option value='right'>Right</option>
                        </Form.Select>
                    </FloatingLabel>
                    <FloatingLabel controlId='selectResponseFormat' label="Select response format" className="px-1 mb-2">
                        <Form.Select value={responseFormat} onChange={e => setResponseFormat(e.target.value)}>
                            <option value='simplified'>Simplified</option>
                            <option value='full'>Full</option>
                        </Form.Select>
                    </FloatingLabel>
                    <FloatingLabel controlId='selectShiftedPolyline' label="Shift polyline ?" className="px-1 mb-2">
                        <Form.Select value={shiftedPolyline} onChange={e => setshiftedPolyline(e.target.value)}>
                            <option value='false'>No</option>
                            <option value='true'>Yes</option>
                        </Form.Select>
                    </FloatingLabel>
                    <FloatingLabel controlId='selectAvoidobstacles' label="Avoid obstacles" className="px-1 mb-2">
                        <Form.Select value={avoidObstacles} onChange={e => setAvoidObstacles(e.target.value)}>
                            <option value='none' style={{fontStyle: "italic"}}>None</option>
                            <option value='all'>All</option>
                        </Form.Select>
                    </FloatingLabel>
                    <FloatingLabel controlId='selectLanguage' label="Select Language" className="px-1 mb-2">
                        <Form.Select value={language} onChange={e => setLanguage(e.target.value)}>
                            <option value='fr'>Français</option>
                            <option value='en'>English</option>
                            <option value='es'>Español</option>
                            <option value='de'>Deutsch</option>
                            <option value='nl'>Nederlands</option>
                        </Form.Select>
                    </FloatingLabel>
                </Form>
            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default FormMoreOptions;